export const pathname = "/search";

export type RouteFunction = (query: QueryParameters<{ q?: string }>) => {
  asPath: string;
  pathname: string;
  query: { pageType: string; q?: string };
};

export const page = {
  route(this: void, query: QueryParameters<{ q?: string }>) {
    return {
      asPath: `${pathname}/${query.q ?? ""}`,
      pathname,
      query: { ...query, pageType: "search" },
    };
  },
};
