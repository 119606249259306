import { PageType } from "@product/scmp-sdk";

import type { Edition } from "shared/lib/edition";

export const pathname = "/home";

export const page = {
  route(
    query: QueryParameters<{
      edition?: Edition;
    }>,
  ) {
    return {
      pathname,
      query: { ...query, pageType: PageType.Homepage },
    };
  },
};
