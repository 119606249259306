import { useCallback } from "react";

import { data } from "shared/data";
import { isValidUrl } from "shared/lib/utils";

import {
  isDrupalRedirect,
  isInternalDomainRoute,
  isStaticRoute,
  routes,
} from "scmp-app/lib/router";

export enum RouteType {
  /**
   * Internal App but is not same domain with this app
   */
  ExternalDomain,
  /**
   * Dynamic route declare in app
   */
  Dynamic,
  /**
   * Internal App and same domain with this app
   */
  InternalDomain,
  /**
   * Require to use drupal redirect
   */
  DrupalRedirect,
  /**
   * Context have url redirect
   */
  Redirect,
  /**
   * Static route declare in app
   */
  Static,
  Other,
}

type RouteEntity = {
  applicationId?: string;
  entityId?: string;
  routeType?: string;
  urlAlias?: string;
  urlRedirectPath?: string;
};

export const useRouteDetector = () => {
  const getUrlAliasByUrl = useCallback((urlValue: string) => {
    let urlAlias = urlValue;

    const isFullUrl = isValidUrl(urlValue);
    if (isFullUrl) {
      const url = new URL(urlValue);
      if (url.host === location.host) urlAlias = url.pathname;
    }

    return urlAlias;
  }, []);

  const getRouteTypeByRouteEntity = useCallback(
    ({ applicationId, routeType = "", urlAlias, urlRedirectPath }: RouteEntity) => {
      if (urlRedirectPath) return RouteType.Redirect;
      else if (applicationId && applicationId !== data.application.scmp.entityId)
        return RouteType.ExternalDomain;
      else if (urlAlias && isInternalDomainRoute(urlAlias)) return RouteType.InternalDomain;
      else if (urlAlias && isDrupalRedirect(urlAlias)) return RouteType.DrupalRedirect;
      else if (routes.dynamics[routeType]) return RouteType.Dynamic;
      else if (urlAlias && isStaticRoute(urlAlias)) return RouteType.Static;
      return RouteType.Other;
    },
    [],
  );

  return {
    getRouteTypeByRouteEntity,
    /**
     * Get the url alias by removing the host if the host is as same as the app
     */
    getUrlAliasByUrl,
  };
};
